import * as React from 'react';

const ProjectsList = ({ projects }) => {
  return (
    <ul className="book-list">
      {[].concat(projects).map((project, i) => {
        const title = project.title;
        const date = project.date;

        return (
          <li className="book-link" key={i}>
            <a href={project.slug} style={{ textDecoration: 'none' }}>
              {title}
              <p className="book-author">{project.date}</p>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default ProjectsList;
