import * as React from 'react';

import Layout from '../components/layout';
import ProjectsList from '../components/projectsList';
import Seo from '../components/seo';

const ProjectsPath = ({ data, location }) => {
  const siteTitle = `Projects`;

  const projects = [
    {
      title: 'Menu Animation',
      date: 'September 2024',
      slug: 'https://toolbar.amb.horse/',
    },
    {
      title: 'Holographic HEB Card',
      date: 'June 2024',
      slug: 'https://imprint-hologram.amb.horse/',
    },
    {
      title: 'Patterns',
      date: 'February 2024',
      slug: 'https://patterns.amb.horse/',
    },
    {
      title: 'VRPay',
      date: 'December 2022',
      slug: 'https://vrpay.vercel.app',
    },
    {
      title: 'Dalledle',
      date: 'June 2022',
      slug: 'https://dalledle.com',
    },

    {
      title: 'VRA Connect',
      date: 'May 2022',
      slug: '/vra-connect',
    },
    {
      title: 'Linglide',
      date: 'March 2021',
      slug: 'https://devpost.com/software/linglide',
    },
  ];

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Projects" />
      <h1 className="main-heading">Projects</h1>
      <div className="subheader">
        These are some of the projects I've built. Enjoy!
      </div>
      <ProjectsList projects={projects} />
    </Layout>
  );
};

export default ProjectsPath;
